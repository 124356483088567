import React, { ReactNode, useEffect } from "react"
import { useDispatch } from "react-redux"
import { graphql, navigate } from "gatsby"
import { useStaticQuery } from "gatsby"
import { updateQueueIds } from "redux/cart"
import { addSlash, decodeShopifyId } from "utils/stringUtils"
import {
  useQueryParams,
  StringParam,
  ArrayParam,
  QueryParamProvider,
} from "use-query-params"
import { parse, stringify } from "query-string"
import { Query } from "typings/graphql"
import { globalHistory, useLocation } from "@reach/router"

const skuQuery = graphql`
  query {
    allShopifyProduct {
      edges {
        node {
          id
          variants {
            id
            sku
            shopifyId: storefrontId
          }
        }
      }
    }
  }
`

const omitQueueData = () => {
  const params = parse(window.location.search)
  const { ids, redirect, ...rest } = params
  const qs = stringify(rest)
  return qs ? `?${qs}` : ""
}

const AddRoute = () => {
  const { allShopifyProduct } = useStaticQuery<Query>(skuQuery)
  const dispatch = useDispatch()
  const [query] = useQueryParams({ ids: ArrayParam, redirect: StringParam })
  const variants = allShopifyProduct?.edges.flatMap(edge => edge.node?.variants)

  useEffect(() => {
    const variantIds = variants.map(variant => {
      const id = decodeShopifyId(variant?.shopifyId || "")
      const sku = variant?.sku || ""
      return { id, sku }
    })
    const ids = query?.ids || []
    const queueIds = ids.map(
      id => variantIds.find(variantId => variantId.sku === id)?.id || id
    )
    const url = query?.redirect ? `${addSlash(query?.redirect)}` : `/`
    const qs = omitQueueData()
    dispatch(updateQueueIds({ queueIds }))
    navigate(`${url}${qs}`, { replace: true })
  }, [])

  return <></>
}

type ProvidersProps = { children: ReactNode }

const WithQueryStringProvider = ({ children }: ProvidersProps) => {
  const location = useLocation()

  return (
    <QueryParamProvider location={location} reachHistory={globalHistory}>
      {children}
    </QueryParamProvider>
  )
}

const Add = () => (
  <WithQueryStringProvider>
    <AddRoute />
  </WithQueryStringProvider>
)

export default Add
